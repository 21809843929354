<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Custom fees</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Custom fees
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createTransactionHeading"
                      class="mt-1 btn btn-block btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add custom fee
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Search by custom fee title"
                      v-model="search.name"
                      v-on:keyup.enter="searchTransactionHeading"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-text-field
                      type="number"
                      label="Price"
                      v-model="search.price"
                      v-on:keyup.enter="searchTransactionHeading"
                      @input="search.price = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" md="2" v-if="currentUser.access_type != 'score'">
                  <v-autocomplete
                      :items="scores"
                      v-model="search.score_id"
                      v-on:keyup.enter="searchTransactionHeading"
                      @input="search.score_id = $event !== null ? $event : ''"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchTransactionHeading"
                      @input="search.is_active = $event !== null ? $event : ''"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined :menu-props="{ bottom: true, offsetY: true}"
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" :md="currentUser.access_type != 'score' ? 3 : 5">
                  <v-btn @click="searchTransactionHeading()" class="btn btn-primary w-35 float-right" :loading="loading" dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>

          </div>

            <!-- table -->
            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Name</th>
                  <th class="px-3">Price</th>
                  <th class="px-3">Score</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="transactionHeadings" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                  <tr v-for="(transactionHeading, index) in transactionHeadings" :key="index">
                    <td class="px-3">
                      <a @click="editTransactionHeading(transactionHeading)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        <i class="fa fa-sort"></i> {{ transactionHeading.name }}
                      </a>
                    </td>
                    <td class="px-3">
                      {{ transactionHeading.amount }}
                    </td>
                    <td class="px-3">
                      {{ transactionHeading.score ? transactionHeading.score : '--' }}
                    </td>
                    <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': transactionHeading.is_active, 'badge-danger': !transactionHeading.is_active }"
                        >{{ transactionHeading.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="editTransactionHeading(transactionHeading)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteTransactionHeading(transactionHeading.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  </draggable>
                  <tr v-if="transactionHeadings.length == 0">
                    <td class="text-center font-weight-bold px-3" colspan="7">No Data Found</td>
                  </tr>
                </template>

              </table>
<!--              <b-pagination-->
<!--                  v-if="transactionHeadings.length > 0"-->
<!--                  class="pull-right mt-7"-->
<!--                  @input="getAllTransactionHeadings"-->
<!--                  v-model="page"-->
<!--                  :total-rows="total"-->
<!--                  :per-page="perPage"-->
<!--                  first-number-->
<!--                  :disabled="loading"-->
<!--                  last-number-->
<!--              ></b-pagination>-->
            </div>
            <!-- table ends -->

            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllTransactionHeadings" ></create-and-update>
    </div>
  </v-app>
</template>
<script>
import TransactionHeadingService from "@/services/transaction-heading/TransactionHeadingService";
import ScoreService from "@/services/score/score/ScoreService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from "vuedraggable";

const transactionHeading = new TransactionHeadingService();
const score = new ScoreService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  name: "Index",
  data() {
    return{
      drag: false,
      loading: false,
      isSorting: false,
      total: null,
      perPage: null,
      page: null,
      transactionHeadings: [],
      scores: [],
      currentUser: {},
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search:{
        name: '',
        price: '',
        score_id: '',
        is_active: '1',
      }
    }
  },
  mounted() {
    this.getAllTransactionHeadings();
    this.getAllScores();
    this.getCurrentUser();
  },
  methods: {
    sort(){
      this.isSorting = true;
      transactionHeading
          .sort(this.transactionHeadings)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Custom fees sorted !!');
            this.getAllTransactionHeadings();
          })
          .catch(err => {

            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    createTransactionHeading(){
      this.$refs['create-and-update'].createTransactionHeading();
    },
    editTransactionHeading(transactionHeading){
      this.$refs['create-and-update'].editTransactionHeading(transactionHeading);
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAllTransactionHeadings(){
      this.loading = true;
      transactionHeading
          .paginate(this.search,this.page)
          .then(response => {
            this.transactionHeadings = response.data.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getAllScores(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchTransactionHeading(){
      this.getAllTransactionHeadings();
    },
    deleteTransactionHeading(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            transactionHeading
                .delete(id)
                .then((response) => {
                  this.getAllTransactionHeadings();
                  this.$snotify.success("Transaction Heading Deleted Successfully");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },

}
</script>