<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} custom fee</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                  v-model="transactionHeading.name"
                  :error="$v.transactionHeading.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Custom fee title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.transactionHeading.name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              <v-text-field
                  type="number"
                  v-model="transactionHeading.amount"
                  :error="$v.transactionHeading.amount.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Price <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.transactionHeading.amount.$error">This information is required</span>
              <span class="text-danger" v-if="errors.amount" >* {{ errors.amount[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" v-if="currentUser.access_type == 'federal'">
              <v-select
                  label="SCORE"
                  v-model="transactionHeading.score_id"
                  :items="scores"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
              >
              </v-select>
            </v-col>



            <v-col cols="6" md="4">
              Status
              <v-switch
                  v-model="transactionHeading.is_active"
                  :label="transactionHeading.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            x-large
            dark
            @click="createOrUpdate()"
        >
          {{ edit ? 'Update' : 'Save' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required } from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
import TransactionHeadingService from "@/services/transaction-heading/TransactionHeadingService";

const transactionHeading = new TransactionHeadingService();
const score = new ScoreService();

export default {
  name: "CreateAndUpdate",
  validations: {
    transactionHeading: {
      name: { required },
      amount: { required },

      is_active: { required }
    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      errors: [],
      scores: [],
      currentUser: {},
      transactionHeading: {
        name:'',
        score_id:'',
        description:'',
        amount:'',
        is_active: true,
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
    }
  },
  methods: {
    closeDialog() {
      this.dialog=false;
      this.$emit('refresh');
    },
    createTransactionHeading() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editTransactionHeading(transactionHeading) {
      this.title = 'Update';
      this.dialog = true;
      this.edit = true;
      this.transactionHeading = transactionHeading;
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAllScore() {
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      this.loading = true;
      transactionHeading
          .create(this.transactionHeading)
          .then(response => {
            this.$snotify.success("Custom fee added");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update() {
      this.loading = true;
      transactionHeading
          .update(this.transactionHeading.id,this.transactionHeading)
          .then(response => {
            this.$snotify.success("Custom fees updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.dialog = true;
      this.errors = [];
      this.$v.$reset();
      this.transactionHeading = {
        name:'',
        amount: '',
        score_id:'',
        description:'',
        is_active: true,
      }
    },
  },
  mounted() {
    this.getAllScore();
    this.getCurrentUser();
  }
}
</script>

<style scoped>

</style>
